// Here you can add other styles
.c-app:not(.c-legacy-theme):not(.c-dark-theme) .btn:not([class*='ghost']):not([class*='link']):not([class*='outline']):not([class*='transparent']) {
    border: 0;
}

div[class*='-menu'] {
    z-index: 999;
}

@media (min-width: 992px) {
    .modal-xxl .modal-lg {
        max-width: 1200px;
    }
}

@media (min-width: 992px) {
    .modal-fullscreen {
        max-width: 100%;
        margin: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        min-height: 100vh;
        display: flex;
    }
}

.min-height-100 {
    min-height: 100px;
    max-height: 100px;
    object-fit: contain;
}

.nc-thumbnail {
    min-height: 100px;
}

.nc-thumbnail-300 {
    min-height: 100px;
    max-height: 300px;
}

.nc-thumbnail-200 {
    min-height: 200px;
    max-height: 200px;
    object-fit: cover;
}

@media (min-width: 992px) {
    .nc-thumbnail {
        min-height: 200px;
    }

    .nc-thumbnail-300 {
        min-height: 100px;
        max-height: 300px;
    }

    .nc-thumbnail-200 {
        min-height: 200px;
        max-height: 200px;
    }

    .nc-thumbnail-100 {
        min-height: 100px;
        max-height: 100px;
        object-fit: contain;
    }
}

.DateInput_input {
    padding: 6px 6px 5px;
}

.c-callout.c-callout-small {
    padding: 0 0.5rem;
    margin: 0.5rem 0;
}

.fade.fade-static:not(.show) {
    display: none;
}

.card-footer.card-footer-tiny {
    padding: 0.15rem 1.25rem;
}

.card-columns.card-gallery.card-columns-8 {
    column-count: 8;
}

.card-columns.card-gallery.card-columns-6 {
    column-count: 6;
}

.card-columns.card-gallery.card-columns-4 {
    column-count: 4;
}

.card-img-inactive {
    opacity: 0.6;
}

.card-img-active {
    opacity: 1;
}

.card-img-interactive {
    cursor: pointer;
}

.card-gallery .card-img-overlay {
    max-height: 50% !important;
    z-index: 1;
}

.card-gallery .card-body {
    padding: 0.5rem;
    z-index: 666;
}

.card-columns.card-gallery {
    @include media-breakpoint-only(md) {
        column-count: 6;
    }

    @include media-breakpoint-only(xl) {
        column-count: 12;
    }
}

.btn-icon-text {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.img-delete .card-img-top {
    opacity: 0.2;
}

.btn-table {
    padding: 2px 4px 4px 4px;
}

.btn-sm.btn-table .c-icon,
.btn.btn-table .c-icon {
    margin: 0;
}

.DateRangePicker {
    display: block !important;
}

.bg-male,
.bg-female,
.bg-divers,
.badge-male,
.badge-female,
.badge-divers,
.btn-female,
.btn-divers,
.btn-male {
    color: #fff;
}

.bg-male,
.badge-male,
.btn-male {
    background-color: #059ef7;
}

.border-start-male {
    border-left-color: #059ef7;
}

.color-male,
.text-male {
    color: #059ef7 !important;
}

.bg-female,
.badge-female,
.btn-female {
    background-color: #e547a1;
}

.border-start-female {
    border-left-color: #e547a1;
}

.color-female,
.text-female {
    color: #e547a1 !important;
}

.bg-divers,
.badge-divers,
.btn-divers {
    background-color: #2eb85c;
}

.border-start-divers {
    border-left-color: #2eb85c;
}

.color-divers,
.text-divers {
    color: #2eb85c !important;
}

.card-accent-male,
.border-top-male {
    border-top: 2px solid #059ef7 !important;
}

.card-accent-female,
.border-top-female {
    border-top: 2px solid #e547a1 !important;
}

.card-accent-divers,
.border-top-divers {
    border-top: 2px solid #2eb85c !important;
}

.list-group.list-small .list-group-item {
    padding: 0.15rem 1.25rem;
}

.row-active-customer {
    background-color: rgba(209, 243, 125, 0.35);
}

.card-gallery .tooltip-inner {
    max-width: none !important;
    width: 100% !important;
}

.bg-highlight {
    background-color: rgba(249, 177, 21, 0.2);
}

/* mod mask */

.message-container {
    border-radius: 10px;
    background-color: #78e08f;
}

.mod_message {
    background-color: #b1b7c1;
}

.img_cont_msg {
    height: 40px;
    width: 40px;
}

.div-1 {
    display: flex;
    flex-direction: column;
}

.div-2 {
    display: flex;
    flex-direction: row;
}

.select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    min-height: calc(1.5em + 0.5rem + 2px);
}

.card-message .card-body,
.card-message .card-footer,
.card-profile .card-body,
.card-profile .card-footer {
    padding: 0.5rem;
}

.card-message {
    width: 20rem;
}

.card-profile {
    width: 18rem;
}

.card-message img {
    object-fit: cover;
    min-width: 100px;
}

.card-message .flex-row img {
    max-width: 100px;
}

.card-profile-stats {
    position: relative;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    align-items: center;
}

.card-profile-stats .badge {
    position: absolute;
    top: 50%;
    margin-top: -12px;
    left: 50%;
    margin-left: 0;
}

table.table-hours thead tr th.hour {
    width: calc(100% / 24);
}

.log-selected-bg {
    background-color: rgba(179, 181, 198, 0.2)
}

.log-compare-bg {
    background-color: rgba(255, 99, 132, 1);
}

.sidebar-brand {
    flex-direction: column;
}

.sidebar-.nav {
    -ms-overflow-style: none;
}

.sidebar-nav::-webkit-scrollbar {
    display: none;
}

div[data-lastpass-icon-root] {
    display: none !important;
}

.btn-link {
    text-decoration: none !important;
}

tr[class^=bg-] td,
tr[class^=bg-],
td[class^=bg-] {
    color: #fff !important;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url(./../assets/fonts/Lato-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'Anton';
    src: local('Anton'), url(./../assets/fonts/Anton-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'Arvo';
    src: local('Arvo'), url(./../assets/fonts/Arvo-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./../assets/fonts/Roboto-Regular.ttf) format('opentype');
}

.header.header-sticky {
    box-shadow: 0 2px 5px -1px rgba(50, 50, 93, .02), 0 1px 3px -1px rgba(0, 0, 0, .05);
}

iframe#webpack-dev-server-client-overlay {
    display: none !important
}

.list-group-plain .list-group-item {
    border-width: 0;
}

.callout-bg {
    --cui-link-color-rgb: var(--cd-callout-link);
    --cui-code-color: var(--cd-callout-code-color);
    background-color: var(--cd-callout-bg, var(--cui-gray-100));
    border-left: 0.25rem solid var(--cd-callout-border, var(--cui-gray-300));
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
    padding: 1.25rem;
}

.callout-bg-info {
    --cd-callout-color: var(--cui-info-text-emphasis);
    --cd-callout-bg: rgba(var(--cui-info-rgb), .1);
    --cd-callout-border: var(--cui-info);
}

.callout-bg-success {
    --cd-callout-color: var(--cui-success-text-emphasis);
    --cd-callout-bg: rgba(var(--cui-success-rgb), .1);
    --cd-callout-border: var(--cui-success);
}

.callout-bg-warning {
    --cd-callout-color: var(--cui-warning-text-emphasis);
    --cd-callout-bg: rgba(var(--cui-warning-rgb), .1);
    --cd-callout-border: var(--cui-warning);
}

.avatar-xxl {
    width: 8rem;
    height: 8rem;
    font-size: 2.6rem;
}

.btn.btn-primary,
.btn.btn-danger,
.btn.btn-warning,
.btn.btn-info,
.btn.btn-dark {
    color: hsla(0, 0%, 100%, .87);
}

.card-image-avatar .card-img-top {
    position: relative;
    height: 100px;
    object-fit: cover;
    vertical-align: middle;
}

.card-image-avatar .avatar {
    text-align: center;
    margin: -4rem auto 0;
    display: block;
    z-index: 1;
}

.profile-view .rounded-circle {
    width: 45px;
    height: 45px;
}

.table-users-list td {
    font-size: 0.9rem !important;
}